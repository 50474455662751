<template>
  <div class="employee-index">
    <transition
      name="fade"
      mode="out-in"
      @beforeLeave="beforeLeave"
      @enter="enter"
      @afterEnter="afterEnter"
    >
      <div key="1" v-if="loader" class="white-row loader-route">
        <div class="lds-sub-ripple">
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-else key="1" class="white-row">
        <!-- // Filter Area -->
        <div class="general-filters">
          <div class="col-sm-12">
            <div class="row">
              <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div class="row top-gen-heading">
                  <h1 class="global-style-heading">
                    Clients
                    <a
                      @click.prevent="refreshClientTable"
                      href=""
                      class="refreshData"
                      ><i class="fas fa-redo-alt"></i
                    ></a>
                  </h1>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="row">
                  <div class="form-actions">
                    <button
                      v-if="getActiveUserData.userRoles == 'admin'"
                      @click="generateReport"
                      class="btn btn-primary"
                    >
                      Download CSV
                    </button>
                    <!-- <router-link
                      type="submit"
                      id="save-form"
                      class="btn btn-primary"
                      :to="{
                        name: 'clients.create'
                      }"
                      tag="button"
                    >
                      <i class="fas fa-plus-circle"></i> Add New Client
                    </router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <form @submit.prevent="getClientData(1)">
                  <div class="row">
                    
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="form-group custom-date-picker custom-padding">
                          <label for="from_date">&nbsp;</label>
                          <datepicker
                            :minimumView="'day'"
                            :maximumView="'month'"
                            :initialView="'month'"
                            :format="customFormatterfromdate"
                            placeholder="From"
                            :input-class="'form-control'"
                            :clear-button="true"
                            :value="formData.from_date"
                            @cleared="onClearDateFromDate"
                          >
                            <span
                              slot="afterDateInput"
                              class="animated-placeholder"
                            >
                              <i class="far fa-calendar-alt"></i>
                            </span>
                          </datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="row">
                        <div class="form-group custom-date-picker custom-padding">
                          <label for="to_date">&nbsp;</label>
                          <datepicker
                            :minimumView="'day'"
                            :maximumView="'month'"
                            :initialView="'month'"
                            :format="customFormattertodate"
                            placeholder="To"
                            :input-class="'form-control'"
                            :clear-button="true"
                            @cleared="onClearDateToDate"
                            :value="formData.to_date"
                          >
                            <span
                              slot="afterDateInput"
                              class="animated-placeholder"
                            >
                              <i class="far fa-calendar-alt"></i>
                            </span>
                          </datepicker>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row">
                        <div class="form-group">
                          <label for="gender" class="control-label"
                            >&nbsp;</label
                          >
                          <div class="input-group">
                            <input
                              v-model="formData.search"
                              @keyup.enter="getClientData(1, 'search')"
                              type="text"
                              class="form-control"
                              placeholder="Press Enter For Search..."
                            />
                            <div class="input-group-append">
                              <button class="btn btn-secondary" type="button">
                                <i class="fa fa-search"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row">
                          <div class="form-group">
                            <label for="brand_id" class="control-label"
                              >&nbsp;</label
                            >
                            <Select2
                              @change="getClientData(1)"
                              v-model="formData.brand_id"
                              :options="brand_options"
                              :settings="{
                                placeholder: 'Select Brand',
                                allowClear: true
                              }"
                            />
                          </div>
                      </div>
                    </div>

                    <div class="col-sm-4"
                       v-if="getActiveUserData.userRoles != 'salesperson'"
                    >
                      <div class="row">
                          <div class="form-group">
                            <label for="brand_id" class="control-label"
                              >&nbsp;</label
                            >
                            <Select2
                              @change="getClientData(1)"
                              v-model="formData.employee_id"
                              :options="project_employees"
                              :settings="{
                                placeholder: 'Sales Person / Sales Team Lead',
                                allowClear: true
                              }"
                            />
                          </div>
                      </div>
                    </div>

                    <div class="col-sm-4">
                      <div class="row">
                        <div  class="form-actions">
                          <button type="submit" class="btn btn-primary default-global-btn"> Search </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="horizontal-row">
          <hr />
        </div>
        <div class="emp-listing">
          <transition
            name="fade"
            mode="out-in"
            @beforeLeave="beforeLeave"
            @enter="enter"
            @afterEnter="afterEnter"
          >
            <div key="1" v-if="client_loader" class="white-row loader-route">
              <div class="lds-sub-ripple">
                <div></div>
                <div></div>
              </div>
            </div>
            <div v-else class="client-page-wrapper">
              <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 ">
                  <div class="row">
                      <div class="box-dash-wraper">
                        <div class="box-dash">
                          <span class="icon-Group-5015"></span>
                          <p class="txt">Total Clients</p>
                          <p class="numb">
                            {{ total_records | numFormat }}
                          </p>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Projects</th>
                      <th>Brand</th>
                      <th>Account Manager</th>
                      <th>Total Paid</th>
                      <th>Total Unpaid</th>
                      <th>Old Payents Sum</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      typeof client_list.data !== 'undefined' &&
                        Object.keys(client_list.data).length
                    "
                  >
                    <tr
                      v-for="(client, cl_index) in client_list.data"
                      :key="cl_index"
                    >
                      <td>
                      <router-link
                          class="user-title eclipse-para global-para-link2"
                          :to="{
                            path: '/clients/' + client.id
                          }"
                          tag="a"
                        >
                        {{ client.name }}
                        </router-link>
                        <!-- <router-link
                          class="invoice-title color-blk customize-global-link"
                          :to="{
                            path: '/invoices/show/' + invoice.id
                          }"
                          tag="a"
                        >
                          {{ invoice.invoice_number }}
                        </router-link> -->
                      </td>
                      <td>
                        {{ client.email }}
                      </td>
                      <td>
                        <p
                          class="project-name"
                          v-for="(prject, project_index) in client.projects"
                          :key="project_index"
                          v-text="prject.project_name"
                        ></p>
                      </td>
                      <td>
                        <p
                          v-for="(brand, brand_index) in client.brand"
                          :key="brand_index"
                          v-text="brand.name"
                        ></p>
                      </td>
                      <td>
                        <div class="rel">
                          <div
                            v-if="client.account_manager.length"
                            class=""
                          >
                            <p
                              class="project-name"
                              v-for="(am, am_index) in client.account_manager"
                              :key="am_index"
                              v-text="am.name"
                            ></p>
                            <!-- </div> -->
                          </div>
                          <div v-else class="usr-role-name">
                            N/A
                          </div>
                        </div>
                      </td>
                      <td>
                        {{ client.brand_currency }}{{ client.total_amount_paid | numFormat('0.00') }}
                      </td>
                      <td>
                        {{ client.brand_currency }}{{ client.total_amount_unpaid | numFormat('0.00') }}
                      </td>
                      <td>
                        {{ client.brand_currency }}{{ client.old_payments_sum | numFormat('0.00') }}
                      </td>
                      <td>
                        {{ client.created_at }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4">
                        <no-record-found></no-record-found>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="view-more-btn"
                  v-if="
                    typeof client_list.data !== 'undefined' &&
                      Object.keys(client_list.data).length
                  "
                >
                </div>
              </div>
              
              
              
              
              <!-- <div
                class="row"
                v-if="
                  typeof client_list.data !== 'undefined' &&
                    Object.keys(client_list.data).length
                "
              >
                <div
                  v-for="(client, cl_index) in client_list.data"
                  :key="cl_index"
                  class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
                >
                  <div class="wrap-box">
                    <div class="header-top">
                      <dropdown>
                        <template slot="icon"
                          ><i class="fas fa-ellipsis-v"></i
                        ></template>
                        <template slot="body">
                          <ul class="list-group">
                            <li class="list-group-item">
                              <router-link
                                :to="{
                                  path: '/clients/edit/' + client.id
                                }"
                                tag="a"
                              >
                                Edit
                              </router-link>
                            </li>
                            <li class="list-group-item">
                              <a href="#"
                                @click="showTermsCondition(cl_index)"
                              >Add Am</a>
                            </li>
                          </ul>
                        </template>
                      </dropdown>
                    </div>
                    <div class="box">
                      <div class="emp-details">
                        <avatar class="mr-3" :username="client.name"></avatar>
                        <router-link
                          class="user-title eclipse-para global-para-link2"
                          :to="{
                            path: '/clients/' + client.id
                          }"
                          tag="p"
                        >
                          {{ client.name }}
                        </router-link>
                        <p class="user-email eclipse-para">
                          {{ client.email }}
                        </p>
                      </div>
                      <div class="box-brand">
                        <div class="brand-para">
                          <p>Brand:</p>
                        </div>
                        <div class="text-right">
                          <p
                            v-for="(brand, brand_index) in client.brand"
                            :key="brand_index"
                            v-text="brand.name"
                          ></p>
                        </div>
                      </div>
                      <div class="footer-box">
                        <div class="footer-box">
                          <div class="usr-role">
                            <p>Account Managers:</p>
                          </div>
                          <div
                            v-if="client.account_manager.length"
                            class="usr-role-name text-right"
                          >
                            <div
                              v-for="(am, am_index) in client.account_manager"
                              :key="am_index"
                              :class="
                                avatarClass(client.account_manager, am_index)
                              "
                            >
                              <avatar
                                :inline="true"
                                :username="am.name"
                              ></avatar>
                              {{
                                client.account_manager_total_count > 3 &&
                                am_index + 1 > 2
                                  ? "+" + client.account_manager_remaining_count
                                  : ""
                              }}
                            </div>
                          </div>
                          <div v-else class="usr-role-name text-right empty_am">
                            N/A
                          </div>
                        </div>

                        <div class="footer-box">
                          <div class="usr-role">
                            <p>Created on: <b> {{ client.created_at }} </b> </p>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="row">
                <div class="col-sm-12">
                  <no-record-found></no-record-found>
                </div>
              </div> -->
            </div>
          </transition>

          <div class="row">
            <div class="col-sm-12">
              <pagination
                class="pagination justify-content-center"
                :data="client_list"
                :limit="1"
                @pagination-change-page="getClientData"
              ></pagination>
            </div>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>
<script>
// import Avatar from "vue-avatar";
// import Dropdown from "bp-vuejs-dropdown";
import Select2 from "v-select2-component";
import norecordfound from "../../../components/no-record-found";
import Datepicker from "vuejs-datepicker";
// import addOrRemoveAmtoClient from "../sub-components/add-am";

export default {
  components: {
    // Avatar,
    // Dropdown,
    Select2,
    "no-record-found": norecordfound,
    Datepicker,
    // "add-or-remove-am-for-cleient" : addOrRemoveAmtoClient,
  },
  data() {
    return {
      loader: true,
      client_loader: true,
      client_list: {},
      formData: {
        search: "",
        brand_id: null,
        employee_id : null,
        from_date : null,
        to_date : null,
      },
      brand_options: null,
      project_employees : null,
      prop_client_index : null,
      prop_client_id : null,
      total_records : 0,
      current_page : 1,
    };
  },
  mounted() {
    document.title =
      "PNC Work Bench - " + this.$router.app._route.meta.pageTitle;
    this.getemployees();
    this.getClientData();
    this.getprojectbrands();
    this.loader = false;
  },
  computed: {
    getActiveUserData() {
      return this.$store.state.auth;
    }
  },
  methods: {
    async generateReport() {
      const $this = this;
      $this.$store.dispatch("generalLoader", {
        response: true
      });
      await window
        .axios({
           url:
          "reports/clients/download-csv?" +
          "&page=" +
          this.current_page +
          "&search=" +
          $this.formData.search +
          "&brand_id=" +
          $this.formData.brand_id +
          "&employee_id=" +
          $this.formData.employee_id +
          
          "&from_date=" +
          $this.formData.from_date +

          "&to_date=" +
          $this.formData.to_date +
          
          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          method: "GET",
          responseType: "blob" // important
        })
        .then(response => {
          // console.log(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "clients.csv"
          );
          document.body.appendChild(link);
          link.click();
          $this.$store.dispatch("generalLoader", {
            response: false
          });
          // $this.$store.dispatch("generalLoader", {
          //   response: false
          // });
        });
    },
    onClearDateFromDate() {
      this.formData.from_date = null;
    },
    onClearDateToDate() {
      this.formData.to_date = null;
    },
    customFormatterfromdate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.from_date = new_val;
      return new_val;
    },
    customFormattertodate(val) {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      var new_val = months[val.getMonth()] +
            " " +
            val.getDate() +
            ", " +
            val.getFullYear();
      this.formData.to_date = new_val;
      return new_val;
    },
    async getemployees() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "/filters/get-employees"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.project_employees = response.data.data;
        }
      });
    },
    refreshClientTable() {
      this.getClientData();
      this.getprojectbrands();
      this.loader = false;
    },
    async getprojectbrands() {
      const $this = this;
      const data = $this.$store.dispatch("getHttp", {
        url: "filters/get-project-brand"
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.brand_options = response.data.data;
        }
      });
    },
    async getClientData(page = 1, action = null) {
      const $this = this;
      if (action == "search") {
        if ($this.formData.search.length < 2 && $this.formData.search != "") {
          return true;
        }
      }
      $this.client_loader = true;
      const data = $this.$store.dispatch("getHttp", {
        url:
          "reports/clients?" +
          "&page=" +
          page +
          "&search=" +
          $this.formData.search +
          "&brand_id=" +
          $this.formData.brand_id +
          "&employee_id=" +
          $this.formData.employee_id +
          
          "&from_date=" +
          $this.formData.from_date +

          "&to_date=" +
          $this.formData.to_date +
          
          "&timezone=" +
          Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      await data.then(function(response) {
        if (typeof response !== "undefined") {
          $this.current_page = response.data.meta.current_page;
          $this.client_list = response.data;
          $this.total_records = response.data.meta.total;
        }
        $this.client_loader = false;
      });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    avatarClass(managers, index) {
      if (managers.length == 1) {
        return "custom-avtr orv-3";
      } else if (managers.length == 2) {
        return "custom-avtr orv-" + (index + 2);
      } else {
        return "custom-avtr orv-" + (index + 1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
body .white-row.loader-route {
  top: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}
.employee-index {
  .general-filters {
    .form-group {
      width: 95%;
      .input-group {
        input {
          border-radius: 3px !important;
          background: #f9f9f9;
          letter-spacing: 0.28px;
          font-size: 14px;
          padding: 3px 15px;
          color: #364460;
          border: none;
          font-weight: 200;
          height: auto;
        }
        button {
          background: #f9f9f9;
          color: #3b4c54;
          border: none;
          padding: 4px 8px;
        }
      }
    }
    .form-actions {
      width: 100%;
      button {
        width: 100%;
        margin-left: 0;
        padding: 5px 0;
      }
    }
    span.select2.select2-container.select2-container--default {
      width: 100% !important;
    }
  }
  .horizontal-row {
    padding: 30px 0 30px;
    hr {
      margin: 0;
    }
  }
  .justify-content-center {
    margin-bottom: 0;
  }
}
.box-dash-wraper {
  width: 100%;
  padding: 0 9px 0 13px;
  margin-bottom: 15px;
  .box-dash {
    background: #faf9f7;
    padding: 20px 15px;
    p {
      margin: 0;
      &.txt {
        margin: 0;
        letter-spacing: 0.28px;
        color: #323f54;
        font-size: 14px;
        font-weight: bold;
        padding: 12px 0 15px;
      }
      &.numb {
        margin: 0;
        letter-spacing: 0.28px;
        color: #323f54;
        font-size: 28px;
        font-weight: bold;
        padding: 0;
      }
    }
    i,
    span,
    svg {
      color: #2d9cea;
      font-size: 23px;
      &:before {
        color: #2d9cea;
      }
    }
  }
}
.table-responsive {
  table {
    margin-bottom: 0;
    thead {
      th {
        letter-spacing: 0.28px;
        color: #2a1c0b;
        font-weight: 200;
        white-space: nowrap;
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          white-space: nowrap;
          p {
            margin-bottom: 0px;
            color: #323f54;
            font-weight: 300;
            letter-spacing: 0.28px;
            &.project-name {
              border-bottom: 1px solid #c1bebecc;
              padding-bottom: 5px;
              &:last-child {
                border-bottom: 0 solid #c1bebecc;
              }
            }
            &.invoice-title {
              letter-spacing: 0.28px;
              font-weight: bold;
            }
            &.invoice-total {
              font-weight: bold;
            }
            &.invoice-status {
              display: inline-block;
              background: #ece9df 0% 0% no-repeat padding-box;
              border: 1px solid #00000000;
              border-radius: 15px;
              padding: 3px 17px;
            }
            &.invoice-icon {
              display: inline-block;
              background: #1190ee;
              color: #fff !important;
              padding: 6px 10px;
              border-radius: 5px;
            }
            &.invoice-status {
              font-size: 10px;
              border-radius: 10px;
              text-transform: capitalize;
              font-weight: bold !important;
              line-height: 20px !important;
              padding: 0 10px;
              text-align: center;
              display: inline-block;
              border: 1px solid #00000000;
              width: 100%;
              &.danger {
                background: red;
                color: #fff !important;
              }
              &.success {
                background: rgba(0, 128, 0, 0.72);
                color: #fff !important;
              }
            }
          }
        }
      }
    }
  }
}
.rel {
  position: relative;
  .usr-role-name {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -12px;
    .custom-avtr {
      display: inline-block;
      position: relative;
      &.orv-1 {
      }
      &.orv-2 {
        left: -11px;
      }
      &.orv-3 {
        left: -22px;
      }
      &.orv-4 {
        left: -34px;
      }
      .vue-avatar--wrapper {
        margin: 0 !important;
        width: 25px !important;
        height: 25px !important;
        padding: 3px 4px 0 !important;
        span {
          font-size: 11px;
        }
      }
    }
    &.empty_am {
      right: 0;
    }
  }
}

// Media Query Start

// Extra large devices (lg and less)
@media screen and (max-width: 1199px) {
}
// Extra large devices (md and less)
@media screen and (max-width: 991px) {
}

// Extra large devices (sm and up)
@media screen and (max-width: 767px) {
  body .top-gen-heading {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

// Extra large devices (xs and up)
@media screen and (max-width: 575px) {
  .employee-index .general-filters .form-group {
    width: 100%;
  }
}
// Extra large devices (xl, 1200px and up)
@media (min-width: 1200) {
}
</style>
